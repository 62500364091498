import React, { useEffect, useState, useCallback } from "react";
import { useParams } from "react-router-dom";
import { supabase } from '../../lib/supabaseClient';

import { saveAs } from "file-saver";
import * as XLSX from "xlsx";

function FormDataPage() {
  const { formId } = useParams();
  const [responses, setResponses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [errorMsg, setErrorMsg] = useState("");

  const fetchResponses = useCallback(async () => {
    setLoading(true);
    setErrorMsg("");
    const { data, error } = await supabase
      .from("responses")
      .select("*")
      .eq("form_id", formId)
      .order("created_at", { ascending: false });

    if (error) {
      setErrorMsg("Error fetching responses: " + error.message);
    } else {
      setResponses(data);
    }
    setLoading(false);
  }, [formId]);

  useEffect(() => {
    fetchResponses();
  }, [fetchResponses]);

  const generateSignedUrl = async (filePath) => {
    const { data, error } = await supabase.storage
      .from("platipus-uploads")
      .createSignedUrl(filePath, 60 * 5);

    if (error) {
      console.error("Error generating signed URL:", error.message);
      return null;
    }

    return data.signedUrl;
  };

  const handleDownload = async (filePath) => {
    const signedUrl = await generateSignedUrl(filePath);
    if (signedUrl) {
      const fileName = filePath.split("/").pop();
      saveAs(signedUrl, fileName);
    } else {
      alert("Failed to generate download link.");
    }
  };

  const handleDelete = async (responseId) => {
    if (!window.confirm("Are you sure you want to delete this response?")) return;

    const { error } = await supabase
      .from("responses")
      .delete()
      .eq("id", responseId);

    if (error) {
      alert("Error deleting response: " + error.message);
    } else {
      setResponses((prev) => prev.filter((res) => res.id !== responseId));
    }
  };

  const handleExport = async (format) => {
    const exportData = await Promise.all(
      responses.map(async (res) => {
        const flat = {};
        for (const [key, val] of Object.entries(res.answers || {})) {
          if (typeof val === "string" && val.startsWith("uploads/")) {
            const signedUrl = await generateSignedUrl(val);
            flat[key] = signedUrl || val;
          } else {
            flat[key] = val;
          }
        }
        return flat;
      })
    );

    const worksheet = XLSX.utils.json_to_sheet(exportData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Responses");

    if (format === "xlsx") {
      XLSX.writeFile(workbook, "form_responses.xlsx");
    } else {
      XLSX.writeFile(workbook, "form_responses.csv", { bookType: "csv" });
    }
  };

  const fields = Object.keys(responses[0]?.answers || {});

  return (
    <div style={{ padding: 20 }}>
      <h2>Form Responses</h2>

      {loading ? (
        <p>Loading responses...</p>
      ) : errorMsg ? (
        <p style={{ color: "red" }}>{errorMsg}</p>
      ) : responses.length === 0 ? (
        <p>No responses yet.</p>
      ) : (
        <>
          <div style={{ marginBottom: 20 }}>
            <button onClick={() => handleExport("xlsx")}>Export to Excel</button>{" "}
            <button onClick={() => handleExport("csv")}>Export to CSV</button>
          </div>

          <table border="1" cellPadding="10">
            <thead>
              <tr>
                {fields.map((field, i) => (
                  <th key={i}>{field}</th>
                ))}
                <th>Submitted At</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {responses.map((response) => (
                <tr key={response.id}>
                  {fields.map((field, j) => {
                    const value = response.answers[field];
                    const isFile =
                      typeof value === "string" && value.startsWith("uploads/");

                    return (
                      <td key={j}>
                        {isFile ? (
                          <button onClick={() => handleDownload(value)}>
                            Download
                          </button>
                        ) : (
                          value
                        )}
                      </td>
                    );
                  })}
                  <td>{new Date(response.created_at).toLocaleString()}</td>
                  <td>
                    <button onClick={() => handleDelete(response.id)}>Delete</button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      )}
    </div>
  );
}

export default FormDataPage;
