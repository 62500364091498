// src/AdminPage.js
import React, { useEffect, useState, useCallback } from "react";
import { supabase } from '../../lib/supabaseClient';

import { useNavigate } from "react-router-dom";

function AdminPage() {
  const [forms, setForms] = useState([]);
  const navigate = useNavigate();

  const fetchForms = useCallback(async () => {
    const { data, error } = await supabase
      .from("forms")
      .select("*")
      .order("created_at", { ascending: false });

    if (error) {
      alert("Error fetching forms: " + error.message);
    } else {
      setForms(data);
    }
  }, []);

  const checkAuthAndFetch = useCallback(async () => {
    const {
      data: { session },
    } = await supabase.auth.getSession();

    if (!session) {
      navigate("/login");
      return;
    }

    fetchForms();
  }, [navigate, fetchForms]);

  useEffect(() => {
    checkAuthAndFetch();
  }, [checkAuthAndFetch]);

  const handleDelete = async (formId) => {
    const confirm = window.confirm(
      "Are you sure you want to delete this form?"
    );
    if (!confirm) return;

    const { error } = await supabase.from("forms").delete().eq("id", formId);

    if (error) {
      alert("Error deleting form: " + error.message);
    } else {
      alert("Form deleted.");
      fetchForms(); // Refresh list
    }
  };

  const handleCopyLink = (formId) => {
    const url = `${window.location.origin}/form/${formId}`;
    navigator.clipboard.writeText(url);
    alert("Link copied to clipboard!");
  };

  const handleLogout = async () => {
    await supabase.auth.signOut();
    navigate("/login");
  };

  return (
    <div style={{ padding: 20 }}>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <h2>Admin Dashboard</h2>
        <button onClick={handleLogout}> Logout</button>
      </div>

      <button onClick={() => navigate("/create-form")}>
        Create New Form
      </button>

      <div style={{ marginTop: 20 }}>
        {forms.length === 0 ? (
          <p>No forms yet.</p>
        ) : (
          <ul>
            {forms.map((form) => (
              <li key={form.id} style={{ marginBottom: 10 }}>
                <strong>{form.name || "(Untitled Form)"}</strong> <br />
                <button onClick={() => navigate(`/form/${form.id}`)}>
                  View Form
                </button>{" "}
                <button onClick={() => handleCopyLink(form.id)}>
                  Copy Link
                </button>{" "}
                <button onClick={() => navigate(`/form-data/${form.id}`)}>
                  View Responses
                </button>{" "}
                <button onClick={() => navigate(`/edit-form/${form.id}`)}>
                  Edit
                </button>{" "}
                <button onClick={() => handleDelete(form.id)}>
                  Delete
                </button>
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
}

export default AdminPage;
