import React, { useState } from "react";
import { supabase } from '../../lib/supabaseClient';
import CameraCapture from "../../components/CameraCapture";
import { useNavigate } from "react-router-dom";

function FormBuilder() {
  const [formName, setFormName] = useState("");
  const [fields, setFields] = useState([]);
  const [newFieldType, setNewFieldType] = useState("text");
  const navigate = useNavigate();

  const addField = () => {
    setFields([
      ...fields,
      {
        label: "",
        type: newFieldType,
        options: ["dropdown", "radio", "checkbox"].includes(newFieldType)
          ? ["Option 1"]
          : [],
        condition: null,
        imageURL: newFieldType === "camera" ? "" : null,
        required: false, // Add required property
      },
    ]);
  };

  const updateField = (index, key, value) => {
    const updatedFields = [...fields];
    updatedFields[index][key] = value;
    setFields(updatedFields);
  };

  const updateOption = (index, optIndex, value) => {
    const updated = [...fields];
    updated[index].options[optIndex] = value;
    setFields(updated);
  };

  const addOption = (index) => {
    const updated = [...fields];
    updated[index].options.push("New Option");
    setFields(updated);
  };

  const removeOption = (index, optIndex) => {
    const updated = [...fields];
    updated[index].options.splice(optIndex, 1);
    setFields(updated);
  };

  const updateCondition = (index, key, value) => {
    const updated = [...fields];
    updated[index].condition = {
      ...(updated[index].condition || {}),
      [key]: value,
    };
    setFields(updated);
  };

  const saveForm = async () => {
    if (!formName || fields.length === 0) {
      alert("Form name and at least one field is required!");
      return;
    }

    // Check if all required fields have values
    for (const field of fields) {
      if (field.required && !field.label) {
        alert("All required fields must have a label.");
        return;
      }
    }

    const { error } = await supabase.from("forms").insert([
      {
        name: formName,
        fields,
        created_at: new Date().toISOString(),
      },
    ]);

    if (error) {
      alert("Error saving form: " + error.message);
    } else {
      alert("Form saved successfully!");
      navigate("/admin");
    }
  };

  return (
    <div style={{ padding: 20 }}>
      <h2>Create New Form</h2>
      <input
        type="text"
        placeholder="Form Name"
        value={formName}
        onChange={(e) => setFormName(e.target.value)}
      />

      <div style={{ marginTop: 20 }}>
        <h4>Add Field</h4>
        <select
          value={newFieldType}
          onChange={(e) => setNewFieldType(e.target.value)}
        >
          <option value="text">Text</option>
          <option value="textarea">Textarea</option>
          <option value="file">File Upload</option>
          <option value="dropdown">Dropdown</option>
          <option value="radio">Radio</option>
          <option value="checkbox">Checkbox</option>
          <option value="camera">Camera</option> {/* ✅ Tambahan */}
        </select>
        <button onClick={addField}>Add</button>
      </div>

      {fields.map((field, i) => (
        <div
          key={i}
          style={{ marginTop: 20, borderTop: "1px solid #ccc", paddingTop: 10 }}
        >
          <input
            type="text"
            placeholder="Label"
            value={field.label}
            onChange={(e) => updateField(i, "label", e.target.value)}
          />
          <span style={{ marginLeft: 10 }}>({field.type})</span>

          {/* Required Checkbox */}
          <label style={{ marginLeft: 10 }}>
            <input
              type="checkbox"
              checked={field.required}
              onChange={(e) => updateField(i, "required", e.target.checked)}
            />
            Required
          </label>

          {/* Untuk tipe dropdown/radio/checkbox */}
          {["dropdown", "radio", "checkbox"].includes(field.type) && (
            <div>
              <strong>Options:</strong>
              {field.options.map((opt, j) => (
                <div key={j}>
                  <input
                    type="text"
                    value={opt}
                    onChange={(e) => updateOption(i, j, e.target.value)}
                  />
                  <button onClick={() => removeOption(i, j)}>Remove</button>
                </div>
              ))}
              <button onClick={() => addOption(i)}>Add Option</button>
            </div>
          )}

          {/* ✅ Untuk tipe camera */}
          {field.type === "camera" && (
            <div style={{ marginTop: 10 }}>
              <CameraCapture
                onUpload={(url) => {
                  updateField(i, "imageURL", url);
                }}
              />
              {field.imageURL && (
                <p>Image uploaded: <a href={field.imageURL} target="_blank" rel="noreferrer">View</a></p>
              )}
            </div>
          )}

          {/* Kondisi tampil */}
          <div style={{ marginTop: 10 }}>
            <strong>Condition (Optional):</strong>
            <br />
            Show if field
            <input
              type="text"
              placeholder="Field Label"
              value={field.condition?.field || ""}
              onChange={(e) => updateCondition(i, "field", e.target.value)}
              style={{ marginLeft: 5 }}
            />
            equals
            <input
              type="text"
              placeholder="Expected Value"
              value={field.condition?.value || ""}
              onChange={(e) => updateCondition(i, "value", e.target.value)}
              style={{ marginLeft: 5 }}
            />
          </div>
        </div>
      ))}

      <br />
      <button onClick={saveForm}>Save Form</button>
    </div>
  );
}

export default FormBuilder;
