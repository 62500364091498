import React from "react";
import { Routes, Route } from "react-router-dom";
import HomePage from './pages/HomePage';
import FormFiller from './pages/user/FormFiller';
import AdminPage from './pages/admin/Dashboard';
import FormBuilder from './pages/admin/FormBuilder';
import EditFormPage from './pages/admin/EditForm';
import FormDataPage from './pages/admin/FormResponses';
import LoginPage from './pages/admin/LoginPage';

import ProtectedRoute from './routes/ProtectedRoute';


function App() {
  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/form/:formId" element={<FormFiller />} />
      <Route path="/login" element={<LoginPage />} />

      {/* ✅ Protected routes */}
      <Route
        path="/admin"
        element={
          <ProtectedRoute>
            <AdminPage />
          </ProtectedRoute>
        }
      />
      <Route
        path="/create-form"
        element={
          <ProtectedRoute>
            <FormBuilder />
          </ProtectedRoute>
        }
      />
      <Route
        path="/edit-form/:formId"
        element={
          <ProtectedRoute>
            <EditFormPage />
          </ProtectedRoute>
        }
      />
      <Route
        path="/form-data/:formId"
        element={
          <ProtectedRoute>
            <FormDataPage />
          </ProtectedRoute>
        }
      />
    </Routes>
  );
}

export default App;
