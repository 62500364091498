import React, { useRef, useState, useEffect } from "react";

function CameraCapture({ onUpload }) {
  const videoRef = useRef(null);
  const [stream, setStream] = useState(null);
  const [error, setError] = useState("");
  const [photoPreview, setPhotoPreview] = useState(null);
  const [facingMode, setFacingMode] = useState("user"); // 'user' = depan, 'environment' = belakang

  const startCamera = async (mode = facingMode) => {
    try {
      // Stop old stream
      if (stream) {
        stream.getTracks().forEach((track) => track.stop());
      }

      const mediaStream = await navigator.mediaDevices.getUserMedia({
        video: { facingMode: mode },
        audio: false,
      });

      if (videoRef.current) {
        videoRef.current.srcObject = mediaStream;
      }

      setStream(mediaStream);
      setPhotoPreview(null);
      setError("");
    } catch (err) {
      setError("Tidak bisa mengakses kamera: " + err.message);
    }
  };

  const stopCamera = () => {
    if (stream) {
      stream.getTracks().forEach((track) => track.stop());
      setStream(null);
    }
  };

  const capturePhoto = () => {
    const video = videoRef.current;
    if (!video || video.readyState !== 4) {
      setError("Video belum siap.");
      return;
    }

    const canvas = document.createElement("canvas");
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;
    canvas.getContext("2d").drawImage(video, 0, 0);

    canvas.toBlob((blob) => {
      const file = new File([blob], `photo_${Date.now()}.jpg`, {
        type: "image/jpeg",
      });
      setPhotoPreview(URL.createObjectURL(blob));
      onUpload(file);
    }, "image/jpeg");
  };

  const toggleFacingMode = async () => {
    const newMode = facingMode === "user" ? "environment" : "user";
    setFacingMode(newMode);
    await startCamera(newMode); // langsung restart kamera
  };

  useEffect(() => {
    return () => {
      stopCamera(); // cleanup on unmount
    };
  }, []);

  return (
    <div>
      {error && <p style={{ color: "red" }}>{error}</p>}

      {!photoPreview && (
        <>
          {!stream && (
            <button type="button" onClick={() => startCamera()}>
              Buka Kamera
            </button>
          )}

          <video
            ref={videoRef}
            autoPlay
            playsInline
            style={{
              width: "100%",
              maxWidth: "300px",
              display: stream ? "block" : "none",
              marginTop: 10,
            }}
          />

          {stream && (
            <div style={{ marginTop: 10 }}>
              <button type="button" onClick={capturePhoto}>
                Ambil Foto
              </button>
              <button
                type="button"
                onClick={toggleFacingMode}
                style={{ marginLeft: 10 }}
              >
                Ganti Kamera
              </button>
            </div>
          )}
        </>
      )}

      {photoPreview && (
        <div>
          <img
            src={photoPreview}
            alt="Preview"
            style={{ width: "100%", maxWidth: "300px", marginTop: 10 }}
          />
          <br />
          <button
            type="button"
            onClick={() => {
              stopCamera();
              setPhotoPreview(null);
              startCamera();
            }}
          >
            Ulangi Ambil Foto
          </button>
        </div>
      )}
    </div>
  );
}

export default CameraCapture;
