// src/HomePage.js
import React from "react";
import { useNavigate } from "react-router-dom";

function HomePage() {
  const navigate = useNavigate();

  return (
    <div style={{ padding: 20 }}>
      <h1>Welcome to Platipus Forms</h1>
      <button onClick={() => navigate("/admin")}>Go to Admin Dashboard</button>
    </div>
  );
}

export default HomePage;
